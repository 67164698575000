ion-thumbnail {
  --border-radius: 5px;
}

ion-button {
  --border-radius: 5px;
}

ion-item {

  .item-native {
    padding-inline-start: 10px !important;
    padding-inline-end: 5px !important;
  }

}
