ion-modal {

  &.force-very-small {
    --height: 195px;
    --width: 335px;
  }

  &.little-small {
    --height: 250px;
    --width: 335px;
  }

  &.force-small {
    --height: 40%;
    --width: 30%;
  }

  &.force-medium {
    --height: 75%;
    --width: 50%;
  }

  &.force-normal {
    --height: 695px;
    --width: 535px;
  }

  &.force-big {
    --height: 80%;
    --width: 80%;
  }

  &.force-fullscreen {
    --height: 100%;
    --width: 100%;

    --border-radius: 0;
  }

  &.mobile {

    --max-height: 850px;
    --min-height: 780px;

    --max-width: 400px;
    --min-width: 350px;

    &::part(content) {
      border: 8px solid #000;
      border-radius: 50px;
      box-shadow: 0 0 30px -10px rgb(0 0 0 / 60%);
    }
  }

  &.opacity-background {
    --background: rgba(44, 29, 45, 0.2);

    &::part(content) {
      backdrop-filter: blur(6px);
    }

    ion-content {
      --background: transparent;

      --padding-top: 25vh;
      --padding-start: 20px;
      --padding-end: 20px;

      ion-datetime {
        border-radius: 8px;
      }
    }
  }

  &.transparent-modal {
    --background: rgba(44, 29, 45, 0);
    backdrop-filter: blur(3px);

    ion-content {
      --background: transparent;

      --padding-top: 25vh;
      --padding-start: 30px;
      --padding-end: 30px;

      ion-datetime {
        border-radius: 8px;
      }
    }
  }

  &.keyboard-modal {
    --height: 950px;
    --width: 100%;

    --background: transparent;
    --border-radius: 5px;
  }

}

@media only screen and (min-width: 615px) {

  ion-modal {

    &.opacity-background {
      ion-content {
        --padding-top: 20%;
      }
    }

    &.transparent-modal {
      --height: 100%;
      --width: 600px;
    }

    &.keyboard-modal {
      --max-width: 1030px;
    }

    &.keyboard-numeric {
      --max-width: 380px;
    }

  }

}
