ion-header {

  &.form-header {

    ion-toolbar {
      --background: var(--ion-color-light-tint);
    }

  }

}

ion-content {

  &.form-page {

    .separator {
      height: 15px;
    }

    sup {
      color: red;
    }

    ion-list-header {
      font-size: 10px;
      text-transform: uppercase;
    }

    ion-item {
      margin: 15px 20px;

      border: 1px dotted #dcdcdc;
      border-radius: 0.42rem;

      ion-label {
        font-size: 16px !important;
      }

      ion-input {
        text-align: right;
      }

      p.unit {
        margin: 0;
        color: #5f5f5f;
      }
    }
  }

}

ion-footer {
  .button {
    width: 100%;
    padding: 5px;
  }
}
