ion-segment {
  width: auto;

  ion-segment-button {
    width: 50%;

    text-transform: inherit;

    --color-checked: #484E63;
    --indicator-color: #484E63;
  }
}
