progress {
  padding: 0 2.5px;

  &[value] {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 10px;
  }

  &[value]::-webkit-progress-bar {
    background-color: #e8e8e8;
    border-radius: 20px;
  }

  &[value]::-webkit-progress-value {
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    -o-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
    background: var(--ion-color-secondary);
    border-radius: 20px;
  }

  &.warning {
    &[value]::-webkit-progress-value {
      background: var(--ion-color-warning);
    }
  }

  &.success {
    &[value]::-webkit-progress-value {
      background: var(--ion-color-success);
    }
  }

}
