ion-fab {

  bottom: 0;
  right: 0;

  ion-fab-button {

    height: 65px;
    width: 65px;

    ion-icon {
      font-size: 30px;
    }

    &::part(native) {
      border-radius: 5px 0 0;
    }

  }

}
