.selector-wrapper {
  position: relative;

  width: 145px;
  margin-top: 10px;

  .select {
    font-size: 13px;

    border: 1px solid #e1e1e1;
    border-radius: 8px;

    ion-select {
      --padding-start: 10px;
    }
  }

  .label {
    position: absolute;
    top: 0;
    left: 10px;

    z-index: 1;
    transform: translateY(-50%);

    padding-right: 5px;

    font-size: 10px;
  }
}
