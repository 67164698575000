/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* Keyboard */
@import 'simple-keyboard/build/css/index.css';

$customColors: ("yurest");
@each $color in $customColors {
  .ion-color-#{$color} {
    --ion-color-base: var(--ion-color-#{$color}) !important;
    --ion-color-base-rgb: var(--ion-color-#{$color}-rgb) !important;
    --ion-color-contrast: var(--ion-color-#{$color}-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-#{$color}-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-#{$color}-shade) !important;
    --ion-color-tint: var(--ion-color-#{$color}-tint) !important;
  }
}

@font-face {
  font-family: time;
  src: url(assets/fonts/alarm-clock.ttf);
}
