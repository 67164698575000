.block-item {
  width: 68%;

  background-color: white;

  margin-left: 2%;
  padding: 17.5px;

  display: grid;
  grid-template-columns: 50% 48%;
  grid-template-rows: 33% 65%;
  gap: 20px;
}
